<template>
  <div>
    <div class="uk-flex uk-flex-between uk-flex-middle">
      <div class="uk-text-large">Empresas</div>
      <div>
        <button
          class="uk-button uk-button-primary uk-border-rounded"
          href="#edit-business-modal"
          uk-toggle
          @click="setNewMode"
        >
          Crear nueva empresa
        </button>
      </div>
    </div>

    <table
      class="
        uk-table
        uk-table-striped
        uk-table-middle
        uk-table-hover
        uk-table-divider
        uk-visible@m
      "
    >
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Apodo</th>
          <th>Dirección</th>
          <th>Teléfono</th>
          <th>NIT</th>
          <th>Representante asignado</th>
          <th>Usuarios asociados</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="!isLoadingBusinesses">
        <tr v-for="business in businesses" :key="business.id">
          <td>{{ business.name }}</td>
          <td>{{ business.nickname }}</td>
          <td>{{ business.address }}</td>
          <td>{{ business.phone }}</td>
          <td>{{ business.nit }}</td>
          <td>
            {{
              business.representable_type === "App\\Models\\SaleTeam"
                ? "Equipo de venta: " + business.representable.name
                : "Vendedor: " +
                  business.representable.first_name +
                  " " +
                  business.representable.last_name
            }}
          </td>
          <td>
            <ul class="uk-list uk-list-bullet">
              <li v-for="user in business.users" :key="user.id">
                {{ user.first_name }} {{ user.last_name }}
              </li>
            </ul>
          </td>
          <td>
            <div class="uk-text-right">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <router-link
                  class="uk-button uk-button-default"
                  uk-icon="expand"
                  :to="'/businesses/' + business.id.toString()"
                ></router-link>
                <button
                  class="uk-button uk-button-default"
                  uk-icon="pencil"
                  href="#edit-business-modal"
                  uk-toggle
                  @click="setEditMode(business)"
                ></button>
                <button
                  class="uk-button uk-button-default"
                  uk-icon="trash"
                  href="#delete-business-modal"
                  uk-toggle
                  @click="setEditMode(business)"
                ></button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="uk-margin-small-top uk-hidden@m">
      <div class="" uk-grid>
        <div
          v-for="business in businesses"
          :key="business.id"
          class="uk-width-1-1"
        >
          <div
            class="uk-card uk-card-default uk-padding-small uk-border-rounded"
          >
            <div class="uk-text-bold uk-text-large">
              {{ business.name }}
              <span v-if="business.nickname"> - {{ business.nickname }}</span>
            </div>
            <div>
              <span class="uk-text-bold">Dirección:</span>
              {{ business.address }}
            </div>
            <div>
              <span class="uk-text-bold">Teléfono:</span>
              {{ business.phone }}
            </div>
            <div>
              <span class="uk-text-bold">NIT:</span>
              {{ business.nit }}
            </div>
            <div>
              <span class="uk-text-bold">Representante asignado:</span>
              {{
                business.representable_type === "App\\Models\\SaleTeam"
                  ? "Equipo de venta: " + business.representable.name
                  : "Vendedor: " +
                    business.representable.first_name +
                    " " +
                    business.representable.last_name
              }}
            </div>
            <div>
              <span class="uk-text-bold">Usuarios asociados:</span>
              <ul class="uk-list uk-list-bullet">
                <li v-for="user in business.users" :key="user.id">
                  {{ user.first_name }} {{ user.last_name }}
                </li>
              </ul>
            </div>
            <div class="uk-text-center">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <router-link
                  class="uk-button uk-button-primary"
                  uk-icon="expand"
                  :to="'/businesses/' + business.id.toString()"
                ></router-link>
                <button
                  class="uk-button uk-button-secondary"
                  uk-icon="pencil"
                  href="#edit-business-modal"
                  uk-toggle
                  @click="setEditMode(business)"
                ></button>
                <button
                  class="uk-button uk-button-primary"
                  uk-icon="trash"
                  href="#delete-business-modal"
                  uk-toggle
                  @click="setEditMode(business)"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isLoadingBusinesses" class="uk-text-center">
      <div uk-spinner></div>
    </div>

    <div
      id="edit-business-modal"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="
          uk-modal-dialog
          uk-modal-body
          uk-margin-auto-vertical
          uk-border-rounded
          uk-overflow-hidden
        "
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Crear</span>
          <span v-else>Editar</span>
          empresa
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <ValidationProvider
            name="Nombres"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Nombres</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Nombres"
              v-model="model.name"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider name="Apodo" rules="required" v-slot="{ errors }">
            <div class="uk-margin-top">Apodo</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Apodo"
              v-model="model.nickname"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider
            name="Dirección"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Dirección</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Dirección"
              v-model="model.address"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider
            name="Teléfono"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Teléfono</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Teléfono"
              v-model="model.phone"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider name="NIT" rules="required" v-slot="{ errors }">
            <div class="uk-margin-top">NIT</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="NIT"
              v-model="model.nit"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider
            name="Representante asignado"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Representante asignado</div>
            <select
              v-if="!isLoadingSalesmen"
              name="Representante asignado"
              v-model="model.representable"
              class="uk-select uk-border-rounded"
            >
              <option
                v-for="representable in [
                  ...salesmen.map((saleman) => ({
                    value: 'App\\Models\\User-' + saleman.id,
                    name:
                      'Vendedor: ' +
                      saleman.first_name +
                      ' ' +
                      saleman.last_name,
                  })),
                ]"
                :key="representable.value"
                :value="representable.value"
              >
                {{ representable.name }}
              </option>
            </select>
            <div>{{ errors.length !== 0 ? errors[0] : "" }}</div>
          </ValidationProvider>

          <div class="uk-text-center uk-margin-top">
            <button
              class="uk-button uk-button-primary uk-border-rounded"
              :disabled="invalid || isLoading"
              @click="mode === 'new' ? store() : update()"
            >
              <span v-if="isLoading" uk-spinner></span>
              <span v-else>
                <span v-if="mode === 'new'">Crear</span>
                <span v-else>Editar</span>
                empresa
              </span>
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>

    <div
      id="delete-business-modal"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="
          uk-modal-dialog
          uk-modal-body
          uk-margin-auto-vertical
          uk-border-rounded
          uk-overflow-hidden
        "
        v-if="selectedBusiness"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          ¿Está seguro que desea eliminar {{ selectedBusiness.name }}?
        </div>

        <div class="uk-text-center uk-margin-top">
          <button
            class="uk-button uk-button-danger uk-border-rounded"
            @click="destroy"
            :disabled="isLoading"
          >
            <span v-if="isLoading" uk-spinner></span>
            <span v-else>Eliminar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
const blankModel = {
  name: null,
  nickname: null,
  address: null,
  phone: null,
  nit: null,
  representable: null,
};

export default {
  name: "BusinesssIndex",

  // props: ["payload"],

  data() {
    return {
      mode: "new",
      model: this._.cloneDeep(blankModel),
      businesses: [],
      isLoadingBusinesses: false,
      salesmen: [],
      isLoadingSalesmen: false,
      selectedBusiness: null,
      isFetching: false,
      isLoading: false,
    };
  },

  mounted() {
    this.fetchBusinesses();
    this.isLoadingSalesmen = true;
    this.axios
      .get("/salesmen")
      .then(({ data }) => {
        this.salesmen = data.salesmen;
      })
      .finally(() => {
        this.isLoadingSalesmen = false;
      });
  },

  methods: {
    resetModel() {
      this.model = this._.cloneDeep(blankModel);
    },
    fetchBusinesses() {
      this.isLoadingBusinesses = true;
      this.axios
        .get("/businesses")
        .then(({ data }) => {
          this.businesses = data.businesses;
        })
        .finally(() => {
          this.isLoadingBusinesses = false;
        });
    },
    setNewMode() {
      this.mode = "new";
      this.selectedBusiness = null;
      this.resetModel();
    },
    setEditMode(business) {
      this.mode = "edit";
      this.selectedBusiness = business;
      this.model.name = business.name;
      this.model.nickname = business.nickname;
      this.model.address = business.address;
      this.model.phone = business.phone;
      this.model.nit = business.nit;
      this.model.representable =
        business.representable_type + "-" + business.representable_id;
    },
    store() {
      this.isLoading = true;
      this.axios
        .post("/businesses", this.model)
        .then(() => {
          this.fetchBusinesses();
          UIkit.modal("#edit-business-modal").hide();
          this.resetModel();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      this.axios
        .put("/businesses/" + this.selectedBusiness.id.toString(), this.model)
        .then(() => {
          this.fetchBusinesses();
          UIkit.modal("#edit-business-modal").hide();
          this.resetModel();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    destroy() {
      this.isLoading = true;
      this.axios
        .delete("/businesses/" + this.selectedBusiness.id.toString())
        .then(() => {
          this.fetchBusinesses();
          UIkit.modal("#delete-business-modal").hide();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
